import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Flex, Box, Image, Text } from 'rebass';
import Layout from '../components/Layout';

import { $fetchPost } from '../components/Shared/state';
import { API_ENDPOINT } from '../lib/config';
import { COLOR } from '../lib/styles';


const withStore = connect((state) => ({
  post: state.Shared.selectedPost
}));

const Wrapper = (C) => withStore(C);

const loaderStyle = {
  width: '100%',
  height: '50vh',
  background: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Lato'
}

const Post = ({ id, dispatch, post }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch($fetchPost(id))
      .then(() => setLoading(false))
  }, []);
  if (loading || !post) return <Flex sx={loaderStyle} fontSize={[2, 3, 4]} color={COLOR.primary}> Please wait ...</Flex>
  
  return (
    <Layout>
      <Box sx={{ textAlign: 'center'}}>
      <Image maxWidth="100%" height="auto" src={`${API_ENDPOINT}${post.Image_URL[0].formats.medium.url}`} />
         <Text fontSize={[4,5,6]} p={2}
           fontFamily="Lato" color={COLOR.primary}
          >{post.Title}</Text>
      </Box>
    </Layout>
  );
};

export default Wrapper(Post);